import '../../scss/main.scss'

import 'bootstrap/dist/js/bootstrap.bundle'

// import Swiper JS
import 'swiper/css/bundle'
import 'swiper/css'

let allSwipers = [];
let swiper_slider;
jQuery(document).ready(function ($) {
    initEvents($);
    initComponentOnReady($);

    if (document.querySelectorAll('.wp-block-gallery.swiper-gallery')) {
        const gallerySliders = document.querySelectorAll('.wp-block-gallery.swiper-gallery');

        gallerySliders.forEach((gallery) => {
            new Swiper(gallery, {
                loop: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                slidesPerView: 1,
                spaceBetween: 0,
            });
        });
    }
});

function initEvents($) {
    //hover submenu
    $('li.dropdown').hover(
        function (e) {
            e.preventDefault();
            $(this).addClass('open')
        },
        function (e) {
            $(this).removeClass('open')
        }
    );

    $('.download-ppid').on('click', function (e) {
        e.preventDefault()
        let url = $('.download-ppid').attr('href') + '&ppid=' + player_ppid;
        window.open(url)
    })

    //animation hamburger menu
    $('.js-hamburger').on('click', function (e) {
        let scrollPosition = 0;
        e.preventDefault();
        $('.menu-list').toggleClass('open');
        $('.hamburger-menu').toggleClass('animate');
        if ($(window).width() < 768) {
            /* Agregado para iOS - Para evitar que el body se mueva al hacer scroll */
            scrollPosition = window.pageYOffset;
            $('body').toggleClass('noscroll');
            if ($('body').hasClass('noscroll')) {
                $('body').css('top', -scrollPosition + 'px');
                $('body').attr('scroll-position', scrollPosition);
            } else {
                let scrollYPosition = $('body').attr('scroll-position');
                window.scrollTo(0, scrollYPosition);
                $('body').css('top', 0);
            }
        }
    });

    // header sticky
    $(window).scroll(function () {
        let header = $('.main-header'),
            content = $('.wrap-content'),
            menuList = $('.menu-list'),
            windowHeight = $(window).height(),
            scroll = $(window).scrollTop();
        if (scroll >= 40 && $(window).width() >= 768) {
            header.addClass('fixed');
            menuList.addClass('fixed');
            content.addClass('displaced');
            $('header .main-header .main-menu nav ul li ul').each(function (i, obj) {
                if ($(this).height() >= (windowHeight - 74)) {
                    $(this).css('height', 'calc(100vh - 74px)');
                } else {
                    $(this).css('height', 'auto');
                }
            });
        } else {
            header.removeClass('fixed');
            menuList.removeClass('fixed');
            content.removeClass('displaced');
            $('header .main-header .main-menu nav ul li ul').each(function (i, obj) {
                if ($(this).height() >= (windowHeight - 108))
                    $(this).css('height', 'calc(100vh - 108px)');
                else
                    $(this).css('height', 'auto');
            });
        }
    });
    //search
    $('.js-search').on('click', function (e) {
        e.preventDefault();
        $('.search-part').toggleClass('hidden');
    });

    $('.search-part #search').on('keydown', 'input', function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.stopImmediatePropagation();
            $('.search-part').toggleClass('hidden');
            $('#searchForm').submit();
        }
    });

    $('.search-part .fa-search').on('click', function (e) {
        e.preventDefault();
        $('.search-part').toggleClass('hidden');
        $('#searchForm').submit();
    });

    /* Profile */
    $('.js-profile').on('click', function (e) {
        e.preventDefault();
        $('.login-part').toggleClass('hidden');
    });

    /* Menu */
    $(".main-menu>li.menu-item").children("a").click(function (e) {
        let li_parent = $(this).parent();
        if (li_parent.children("ul").length) {
            e.stopPropagation();
            e.preventDefault();
            li_parent.toggleClass('active');
        } else {
            $(".hamburger-menu").toggleClass('animate');
            $(".menu-list").toggleClass('open');
        }

        if (li_parent.children("span").length) {
            let icon = li_parent.children("span").children("i");
            if (li_parent.hasClass("active")) {
                icon.removeClass("fa-angle-down");
                icon.addClass("fa-angle-up");
            } else {
                icon.removeClass("fa-angle-up");
                icon.addClass("fa-angle-down");
            }
        }
    });

    $(".main-menu>li.menu-item>span").click(function (e) {
        let li_parent = $(this).parent();
        li_parent.children("a").click();
    });

    //functions with resize
    jQuery(window).on('resize', function () {
        Object.entries(allSwipers).forEach(([key, swiper]) => {
            try {
                jQuery(key).each(function () {
                    this.swiper.destroy();
                });
            } catch (e) { }
            document.querySelectorAll(key + ' .swiper-wrapper').forEach(el => {
                el.removeAttribute("style");
            });
            document.querySelectorAll(key + ' .swiper-slide').forEach(el => {
                el.removeAttribute("style");
            });
        });
        allSwipers = [];
        initSwipers();
        initOnClick();
    });
}

function initSwipers() {
    initSwiperWeekDay();
    initSwiper('.swiper-hoy-radio');
    initSwiper('.swiper-seccion-especial-publi', false, 'auto', 'auto', 1, false, true, 0, true);
    initSwiper('.swiper-seccion-especial-nopubli', false, 'auto', 'auto', 1, false);
    initSwiper('.swiper-videos', false, 'auto', 'auto', 1, false);
    initSwiper('.swiper-programs', false, 'auto', 4, 1, false, true, 0, true);
    initSwiper('.swiper-mas-visto');
    initSwiper('.swiper-giras', true, 'auto', 2, 1);
    initSwiper('.swiper-last-audio-block', false, 'auto', 'auto', 1, false, true, 0, true);
    initSwiper('.swiper-web-stories', true, 1, 1, 1, true);
    initFeaturedSliders();
    customHeight();
}

function initOnClick() {
    jQuery('.js-days ul li').on('click', function () {
        let id_slide = jQuery(this).data('id')
        jQuery('.js-days ul li').removeClass('active');
        jQuery(this).addClass('active');
        jQuery('.swiper-program-day').each(function () {
            this.swiper.slideTo(id_slide, 1000);
        });
    });
    if (jQuery('#datepicker').length > 0) {
        jQuery("#datepicker").change(function () {
            let date = jQuery(this).datepicker({
                dateFormat: 'dd/mm/yy'
            }).val();
            jQuery("#date-check span").text(date);
            jQuery("#date-check span").attr('data-date', date);
        });
        jQuery('#date-check').on('click', function (e) {
            e.preventDefault();
            jQuery(".select-date-list").toggleClass('visible-calendar');
        });
    }
}

window.initComponentOnReady = function ($) {
    Object.entries(allSwipers).forEach(([key, swiper]) => {
        try {
            jQuery(key).each(function () {
                this.swiper.destroy();
            });
        } catch (e) { }
        document.querySelectorAll(key + ' .swiper-wrapper').forEach(el => {
            el.removeAttribute("style");
        });
        document.querySelectorAll(key + ' .swiper-slide').forEach(el => {
            el.removeAttribute("style");
        });
    });
    allSwipers = [];
    initSwipers();

    $('.tabcontent#week ').css('display', 'block');
    $('.tabcontent ').css('display', 'none');
    $('.tabcontent#week ').css('display', 'block');
    if ($('#datepicker').length > 0) {
        $.datepicker.regional['es'] = {
            closeText: 'Cerrar',
            prevText: '<Ant',
            nextText: 'Sig>',
            currentText: 'Hoy',
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
            dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
            weekHeader: 'Sm',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        $.datepicker.setDefaults($.datepicker.regional['es']);
        $("#datepicker").datepicker({
            maxDate: "+0m +0w",
            numberOfMonths: 1,
            dateFormat: 'yy/mm/dd',
            beforeShowDay: function (date) {
                if (date.getDay() == $("#date-check span").attr('data-releaseday')) {
                    return [true];
                } else {
                    return [false];
                }
            }
        });
        $(".select-date-list").addClass('visible-calendar');
    }
    initOnClick();
}

jQuery('#weekly ').addClass('active');

function openSelect(event, selection) {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(selection).style.display = "block";
    event.currentTarget.className += " active";
}

function initSwiper(className, loop = true, slidesPerViewMobile = 'auto', slidesPerView = 'auto', slidesPerGroup = 1, center = true, forceSlider = false, initialSlide = 0, navigation = false) {
    if (navigation) {
        navigation = {
            nextEl: '.js-btn-slider-next',
            prevEl: '.js-btn-slider-previous',
        }
    }
    initFullCustomSwiper(className, {
        direction: 'horizontal',
        loop: loop,
        //slidesPerView: slidesPerViewMobile,
        spaceBetween: 8,
        centeredSlides: center,
        slidesPerGroup: slidesPerGroup,
        initialSlide: initialSlide,
        breakpoints: {
            0: {
                slidesPerView: slidesPerViewMobile,
                spaceBetween: 0
            },
            768: {
                slidesPerView: slidesPerView
            }
        },
        navigation: navigation
    }, forceSlider);
}

function initFeaturedSliders() {
    try {
        jQuery('.swiper-slider').each(function () {
            let classes = jQuery(this).attr("class").split(/\s+/);
            let classRnd = classes.find(c => c.includes("swiper-rnd-"));

            if (classRnd) {
                allSwipers['.' + classRnd] = new Swiper('.' + classRnd, {
                    direction: 'horizontal',
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    effect: jQuery(this).data('transition_type'),
                    autoplay: {
                        delay: jQuery(this).data('transition_time'),
                    },
                    pagination: {
                        clickable: true,
                        el: '.' + classRnd + ' .box-slider .swiper-pagination',
                    },
                    loop: true
                });
            } else {
                allSwipers[className] = new Swiper(className, {
                    direction: 'horizontal',
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    effect: jQuery(this).data('transition_type'),
                    autoplay: {
                        delay: jQuery(this).data('transition_time'),
                    },
                    pagination: {
                        clickable: true,
                        el: className + ' .box-slider .swiper-pagination',
                    },
                    loop: true
                });
            }
        });
    } catch (e) {
        if (typeof (wp_debug) != 'undefined' && wp_debug) {
            console.log('[DEBUG] initFeaturedSliders', e);
        }
    }
}

function initFullCustomSwiper(className, data, forceSlider) {
    let screenWidth = screen.width;
    if ((screenWidth <= 767 && typeof allSwipers[className] === 'undefined') || forceSlider) {
        try {
            jQuery(className).each(function () {
                let classes = jQuery(this).attr("class").split(/\s+/);
                let classRnd = classes.find(c => c.includes("swiper-rnd-"));

                if (classRnd) {
                    allSwipers['.' + classRnd] = new Swiper('.' + classRnd, data);
                } else {
                    allSwipers[className] = new Swiper(className, data);
                }
            });
        } catch (e) {
            if (typeof (wp_debug) != 'undefined' && wp_debug) {
                console.log('[DEBUG] initFullCustomSwiper', e);
            }
        }
    } else if (screenWidth > 767) {
        if (typeof allSwipers[className] != 'undefined') {
            try {
                jQuery(className).each(function () {
                    this.swiper.destroy();
                });
            } catch (e) {
                if (typeof (wp_debug) != 'undefined' && wp_debug) {
                    console.log('[DEBUG] initFullCustomSwiper', e);
                }
            }
            delete allSwipers[className];
        }
        document.querySelectorAll(className + ' .swiper-wrapper').forEach(el => {
            el.removeAttribute("style");
        });
        document.querySelectorAll(className + ' .swiper-slide').forEach(el => {
            el.removeAttribute("style");
        });
    }
}

function customHeight() {
    let widthFeatured = jQuery('article.featured-square').width();
    jQuery('article.featured-square').css('height', widthFeatured);

    let screenWidth = screen.width;

    let heightDestacado = jQuery('.column-destacado .image img').height();
    jQuery('.info-box-destacado').css('height', heightDestacado);

    if (screenWidth < 768) {
        jQuery('article.featured').css('height', 'auto');
        jQuery('.info-box-destacado').css('height', 'auto');
    }
    if (screenWidth >= 768 && screenWidth < 1200) {
        let heightPlaylist = jQuery('.block-list-other').height();
        jQuery('.block-list-feature').css('height', heightPlaylist);
    }

    if (screenWidth >= 768) { //Agregado para arreglar la altura de la publicidad en mobile

        jQuery('.js-hoy-radio').each(function () {
            let heightNewsRadio = jQuery(this).find('.block-noticias').height();
            jQuery(this).find('.publi-desktop.sticky').css('height', heightNewsRadio);
        });

        jQuery('.js-seccion-especial-publi').each(function () {
            let heightNews = jQuery(this).find('.block-noticias').height();
            jQuery(this).find('.publi-desktop.sticky').css('height', heightNews);
        });

        jQuery('.js-seccion-especial-publi').each(function () {
            let heightNews = jQuery(this).find('.block-noticias').height();
            if (!heightNews) {
                heightNews = jQuery(this).find('.block-noticias-no-slider').height();
            }
            jQuery(this).find('.publi-desktop.sticky').css('height', heightNews);
        });

        jQuery('.two-cols-publi').each(function () {
            let heightPost = jQuery(this).find('.block-noticias').height();

            if (typeof heightPost === "undefined")
                heightPost = jQuery(this).find('.block-noticias-no-slider').height();

            let anuncios = jQuery(this).find('.publi-desktop.sticky');

            if (anuncios.length > 0) {
                let anuncioTop = anuncios.position().top;
                anuncios.css('height', heightPost - anuncioTop);
            }
        });
    } else { //Agregado para arreglar la altura de la publicidad en mobile
        jQuery('.js-hoy-radio .publi-desktop.sticky').css({
            'height': 'auto',
            'margin-bottom': '10px'
        });
        jQuery('.js-seccion-especial-publi .publi-desktop.sticky').css({
            'height': 'auto',
            'margin-bottom': '10px'
        });
    }
}

function initSwiperWeekDay() {
    initFullCustomSwiper('.swiper-program-day', {
        spaceBetween: 16,
        autoHeight: true,
        on: {
            slideChangeTransitionEnd: function () {
                id_slide_active = jQuery('.swiper-program-day .swiper-slide-active').data('slide');
                jQuery('.js-days ul li').removeClass('active');
                jQuery(".js-days ul li[data-id='" + id_slide_active + "']").addClass('active');
            },
        }
    }, true);
}

window.sendEventLaunch = function (eventName, data = null, social = null) {
    try {
        if (eventName == 'share') {
            DTM.trackEvent(eventName, {
                "shareRRSS": social
            });
        }
    } catch (e) {
        console.log(e);
    }
}

function wpSearch(e) {
    return wpSearchGenenal(e, "search");
}

function wpSearchMobile(e) {
    return wpSearchGenenal(e, "search_h");
}

window.wpSearchGenenal = function (e, id) {
    e.preventDefault();
    let formValue = document.getElementById(id).value;
    document.getElementById("search-link").href = location.origin + "?s=" + formValue;
    document.getElementById("search-link").click();

    return false;
}

function showShareTap(slider) {
    try {
        document.getElementById("slider-tap-share-" + slider).style.height = "100%";
    } catch (e) { }
}

function hideShareTap(slider) {
    try {
        document.getElementById("slider-tap-share-" + slider).style.height = "0";
    } catch (e) { }
}